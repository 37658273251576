import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../img/jmaclogistics-logo.png';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-6">
          <img src={LogoIcon} alt="JmacLogistics" />
        </div>
        Jmac Logistics
      </div>
      <div className="flex-2 mt-4">
        <AnchorLink className="px-4" href="#services">
          Services
        </AnchorLink>
        <AnchorLink className="px-4" href="#features">
          Features
        </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
